@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import '~antd/lib/style/color/colors.less';

@primary-color: #5092aa;
@layout-sider-background: #005f68;
@menu-dark-bg: #005f68;
@menu-dark-inline-submenu-bg: #005f68;

:root {
  --menu-color: #005f68;
  --divider: #d0dce0;
  --primary-color: #5092aa;
  --secondary-color: #54679c;
  --cell-even-color: #f3f6f7;
  --popup-x: 0px;
  --popup-y: 0px;
}

* {
  margin: 0;
  padding: 0;
}

.ant-btn-link {
  color: #6c2cc0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
iframe {
  z-index: -1 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-head {
  border-bottom: 0;
}

.ant-card-extra {
  padding: 0;
}

.ant-card-body {
  padding: 0;
}

.ant-btn {
  transition: all 0.3s ease-in-out;
}

.ant-card.ant-card-bordered {
  border-radius: 10px;
  box-shadow: 0 0 10px 0.1px #dfdfdf;
}

.header-form {
  padding: 16px @padding-lg;
  margin-bottom: @margin-sm;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 10px !important;
  box-shadow: 0 0 10px 0.1px #dfdfdf;
  transition: all 0.3s;
}

.ant-layout-sider-dark {
  padding: @padding-md;
  border-radius: 0 @padding-md @padding-md 0;
  margin-top: 1px;
  .ant-btn:hover {
    border: 1 solid var(--menu-color);
  }
  .menu-button {
    margin: 16px;
  }
}

.ant-layout-header {
  background: var(--menu-color);
}

.ant-layout-sider-collapsed {
  .menu-button {
    margin: 16px 0px 16px 0px;
  }
}

.menu-button {
  color: var(--menu-color);
  border: none;
}

.menu-button:hover {
  color: var(--menu-color);
  opacity: 0.9;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-radius: @padding-sm;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  border-radius: @padding-sm;
}

.padding-content {
  padding: @padding-lg;
}

.border-top {
  border-top: 1px solid #f0f0f0;
}

.margin-bottom {
  margin-bottom: @margin-md;
}

.avatar {
  padding: @padding-lg;
  border-top: 1px solid #f0f0f0;
}

.ant-table-tbody > tr > td:first-child {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  transition: background 0.3s;
  padding: 4px;
  text-align: center;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  transition: background 0.3s;
  padding: 4px 8px;
}
.ant-table-thead > tr > th:first-child {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  padding: 4px;
  transition: background 0.3s;
  text-align: center;
}
.ant-table-thead > tr > th {
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  padding: 4px 8px;
  transition: background 0.3s;
}
.ant-modal-footer {
  text-align: left;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle
  .ant-descriptions-item-content {
  width: 50%;
}
.ant-descriptions-view {
  width: 100%;
}
.ant-notification-notice-message {
  font-size: 16px !important;
}

.ant-notification-notice {
  white-space: pre-line;
  svg {
    height: 15px !important;
    width: 15px !important;
  }
}

.ant-notification-notice-description {
  font-size: 13px !important;
}

.ellipsis-cell {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  margin: 0px;
}

.menu-button {
  margin: 16px;
}

.table-row:nth-child(even) td {
  background: var(--cell-even-color) !important;
}

.ant-modal-content {
  .meal__modal--products {
    display: flex;

    .ant-form-vertical .ant-form-item {
      flex-direction: row;
      background-color: red;
    }

    span {
      display: inline;
    }
  }
}

@border-radius-base: 5px;